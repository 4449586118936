import React from 'react';
import './App.css';

function App() {
  return (
    <div className="svg-container">
      <svg width="220" viewBox="0 0 267 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 1.2601H15.6975V56.8051H40.845V70.5601H0V1.2601Z" fill="#F9FC61" />
        <path d="M46.02 45.6226L22.1325 1.2601H41.19L53.8425 27.6676L66.5475 1.2601H85.5L61.7175 45.6226V70.5601H46.02V45.6226Z" fill="#F9FC61" />
        <path d="M104.5 17.5876L85.6727 11.0001L86 70.5601H67.7225V45.6226L90.7225 1.2601H107.35L121 53.5001L130 1.2601V70.5601L106 42.5776L104.5 17.5876Z" fill="#F9FC61" />
        <path d="M152.5 70.5601H136.488V1.2601H152.5V70.5601Z" fill="#F9FC61" />
        <path d="M192.305 44.74V37.7575C191.99 38.4575 191.185 39.4725 189.89 40.8025C188.63 42.1325 186.95 43.34 184.85 44.425C182.75 45.475 180.3 46 177.5 46C173.3 46 169.625 44.985 166.475 42.955C163.325 40.89 160.875 38.1425 159.125 34.7125C157.375 31.2475 156.5 27.415 156.5 23.215C156.5 19.015 157.375 15.2 159.125 11.77C160.875 8.30499 163.325 5.53999 166.475 3.475C169.625 1.41 173.3 0.377495 177.5 0.377495C180.195 0.377495 182.557 0.814994 184.587 1.68999C186.617 2.52999 188.263 3.54499 189.523 4.73499C190.783 5.88999 191.658 6.95749 192.148 7.93749V1.63749H208.003V44.74H192.305ZM172.145 23.215C172.145 25.175 172.6 26.9425 173.51 28.5175C174.42 30.0575 175.628 31.265 177.133 32.14C178.673 33.015 180.37 33.4525 182.225 33.4525C184.15 33.4525 185.848 33.015 187.318 32.14C188.788 31.265 189.943 30.0575 190.783 28.5175C191.658 26.9425 192.095 25.175 192.095 23.215C192.095 21.255 191.658 19.505 190.783 17.965C189.943 16.39 188.788 15.165 187.318 14.29C185.848 13.38 184.15 12.925 182.225 12.925C180.37 12.925 178.673 13.38 177.133 14.29C175.628 15.165 174.42 16.39 173.51 17.965C172.6 19.505 172.145 21.255 172.145 23.215Z" fill="#F9FC61" />
        <path d="M214.5 70.5601V1.2601L230.46 1.2601V33.7576C230.775 32.7776 231.58 31.7101 232.875 30.5551C234.205 29.3651 235.937 28.3501 238.072 27.5101C240.242 26.6351 242.692 26.1976 245.422 26.1976C249.622 26.1976 253.262 27.2301 256.342 29.2951C259.422 31.3601 261.802 34.1251 263.482 37.5901C265.162 41.0201 266.003 44.8351 266.003 49.0351C266.003 53.2351 265.162 57.0676 263.482 60.5326C261.802 63.9626 259.422 66.7101 256.342 68.7751C253.262 70.8051 249.622 71.8201 245.422 71.8201C243.112 71.8201 240.925 71.4001 238.86 70.5601C236.83 69.7551 235.062 68.7226 233.557 67.4626C232.087 66.1676 231.002 64.8726 230.302 63.5776V70.5601H214.5ZM250.357 49.0351C250.357 47.0751 249.92 45.3251 249.045 43.7851C248.205 42.2101 247.032 40.9851 245.527 40.1101C244.057 39.2001 242.395 38.7451 240.54 38.7451C238.615 38.7451 236.882 39.2001 235.342 40.1101C233.837 40.9851 232.647 42.2101 231.772 43.7851C230.897 45.3251 230.46 47.0751 230.46 49.0351C230.46 50.9951 230.897 52.7626 231.772 54.3376C232.647 55.8776 233.837 57.0851 235.342 57.9601C236.882 58.8351 238.615 59.2726 240.54 59.2726C242.395 59.2726 244.057 58.8351 245.527 57.9601C247.032 57.0851 248.205 55.8776 249.045 54.3376C249.92 52.7626 250.357 50.9951 250.357 49.0351Z" fill="#F9FC61" />
        <path d="M164.383 51.444C165.871 51.444 166.807 52.284 166.843 53.82H165.739C165.727 52.944 165.175 52.428 164.359 52.428C163.555 52.428 163.063 52.896 163.063 53.7C163.063 54.084 163.183 54.372 163.411 54.576C163.651 54.768 164.095 54.996 164.731 55.248C165.595 55.572 166.207 55.908 166.567 56.268C166.927 56.628 167.107 57.108 167.107 57.72C167.107 59.22 166.051 60.156 164.503 60.156C162.763 60.156 161.755 59.124 161.719 57.528H162.823C162.835 58.596 163.459 59.184 164.515 59.184C165.475 59.184 166.003 58.62 166.003 57.792C166.003 57.084 165.535 56.688 164.239 56.244C163.459 55.956 162.883 55.632 162.511 55.284C162.139 54.924 161.959 54.42 161.959 53.784C161.959 53.1 162.175 52.548 162.595 52.104C163.027 51.66 163.627 51.444 164.383 51.444Z" fill="#F9FC61" />
        <path d="M170.903 51.6H171.995V60H170.903V51.6Z" fill="#F9FC61" />
        <path d="M181.848 51.6H182.94V60H181.86L177.684 53.976L177.36 53.46V60H176.268V51.6H177.336L181.488 57.6L181.848 58.152V51.6Z" fill="#F9FC61" />
        <path d="M190.895 51.444C192.827 51.444 194.147 52.428 194.627 54.096H193.415C193.019 53.004 192.179 52.464 190.883 52.464C190.007 52.464 189.287 52.776 188.723 53.412C188.159 54.036 187.883 54.828 187.883 55.8C187.883 56.76 188.159 57.552 188.723 58.188C189.287 58.824 190.019 59.136 190.919 59.136C192.515 59.136 193.535 58.308 193.583 56.712H191.063V55.692H194.735V56.256C194.735 57.468 194.399 58.428 193.715 59.124C193.031 59.808 192.107 60.156 190.919 60.156C189.707 60.156 188.699 59.748 187.919 58.92C187.139 58.092 186.743 57.06 186.743 55.8C186.743 54.54 187.127 53.508 187.907 52.68C188.687 51.852 189.683 51.444 190.895 51.444Z" fill="#F9FC61" />
        <path d="M201.76 53.46L201.604 53.004L201.448 53.46L200.128 56.76H203.092L201.76 53.46ZM201.052 51.6H202.18L205.552 60H204.352L203.464 57.78H199.756L198.88 60H197.68L201.052 51.6Z" fill="#F9FC61" />
        <path d="M162.335 61.6H164.819C165.659 61.6 166.331 61.84 166.823 62.32C167.315 62.788 167.567 63.412 167.567 64.18C167.567 64.936 167.315 65.56 166.823 66.028C166.331 66.496 165.659 66.724 164.819 66.724H163.427V70H162.335V61.6ZM164.819 62.62H163.427V65.704H164.819C165.815 65.704 166.463 65.128 166.463 64.18C166.463 63.22 165.815 62.62 164.819 62.62Z" fill="#F9FC61" />
        <path d="M177.827 61.444C179.063 61.444 180.095 61.852 180.899 62.68C181.703 63.508 182.111 64.54 182.111 65.788C182.111 67.048 181.703 68.092 180.899 68.92C180.095 69.748 179.063 70.156 177.827 70.156C176.579 70.156 175.547 69.748 174.743 68.92C173.939 68.092 173.531 67.048 173.531 65.788C173.531 64.54 173.939 63.508 174.743 62.68C175.547 61.852 176.579 61.444 177.827 61.444ZM177.827 62.464C176.903 62.464 176.135 62.776 175.547 63.412C174.959 64.036 174.671 64.828 174.671 65.788C174.671 66.76 174.959 67.552 175.547 68.188C176.135 68.824 176.903 69.136 177.827 69.136C178.751 69.136 179.495 68.824 180.083 68.188C180.671 67.552 180.971 66.76 180.971 65.788C180.971 64.828 180.671 64.036 180.083 63.412C179.495 62.776 178.751 62.464 177.827 62.464Z" fill="#F9FC61" />
        <path d="M188.686 61.6H190.978C192.658 61.6 193.726 62.596 193.726 64.132C193.726 65.236 193.138 66.064 192.13 66.412L194.086 70H192.838L191.002 66.592H189.778V70H188.686V61.6ZM190.978 62.62H189.778V65.608H190.978C191.974 65.608 192.622 65.056 192.622 64.132C192.622 63.184 191.974 62.62 190.978 62.62Z" fill="#F9FC61" />
        <path d="M200.42 61.6H205.04V62.62H201.512V65.164H204.62V66.184H201.512V68.98H205.04V70H200.42V61.6Z" fill="#F9FC61" />
      </svg>
    </div>
  );
}

export default App;
